import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { SelectedItemEmiter } from 'src/app/utils/SelectedItemEmiter';
import { CopyPaste } from 'src/app/utils/textEditor/CopyPaste';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MedicalReportLogService } from 'src/app/services/MedicalReportLog.service';
import { ConfirmDialogComponent } from 'src/app/utils/dialog/confirm-dialog/confirm-dialog.component';
import { TermosGenericos } from '../../../../models/Helper/TermosGenericos';
import { DescricaoConclusao } from '../../../../models/Helper/DescricaoConclusao';
import { TermosDinamicos } from 'src/app/models/Helper/TermosDinamicos';
import { Injuries, Term } from '../../../../models/BO/Term';
import { TermResult } from 'src/app/models/BO/TermResult';
import { LaudosService } from 'src/app/services/laudos.service.spec';
import { MedicalReportService } from 'src/app/services/medicalReport.service';
import { Validacoes } from 'src/app/utils/validacoes';
import { PouchDBService } from 'src/app/services/pouchdb.service';
import { CommonService } from 'src/app/services/common.service';
import { AddText, IAtualizaLinha } from 'src/app/utils/addText';
import { ConfigLaudoService } from 'src/app/services/config-laudo.service';
import { DataTermService } from 'src/app/services/dataTerm.service';
import { ControladorImprimirService } from 'src/app/services/controladorImprimir.service';

@Component({
  selector: 'app-transv-seg-terc-trimestre',
  templateUrl: './transv-seg-terc-trimestre.component.html',
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s ease',
              style({
                opacity: 0,
                transform: 'translateX(-50px)'
              }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s ease',
              style(
                {
                  opacity: 1,
                  transform: 'translateX(0px)'
                }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  styleUrls: ['./transv-seg-terc-trimestre.component.css']
})
export class TransvSegTercTrimestreComponent implements OnInit, AfterViewInit {
  @ViewChild('inCodLaudo') inCodLaudo: ElementRef;

  listTermDefault=[
    {listTerm:[1977],blockID:178},
    {listTerm:[1978],blockID:179},
    {listTerm:[1986],blockID:180},
    {listTerm:[1996,1997,1998],blockID:181},
    {listTerm:[4703],blockID:306},
    {listTerm:[2002],blockID:183}
  ]

  descricoes: DescricaoConclusao[] = [
    { value: 'Útero globoso e proeminente, apresentando no seu interior 1 feto.', blockID: 178, textTermID: 0, visivel: true },
    {
      value: 'Feto localizado em situação ***, apresentação ***, com posição de dorso à ***. <br>' +
        'Movimentos corpóreos e batimentos cardíacos presentes.<br>' +
        'Frequência cardíaca em torno de *** bpm.', blockID: 179, textTermID: 0, visivel: true
    },
    {
      value: '<b>BIOMETRIA FETAL</b><br>' +
        'Diâmetro biparietal (DBP): *** mm.<br>' +
        'Diâmetro occiptofrontal (DOF): *** mm.<br>' +
        'Circunferência cefálica (CC): *** mm.<br>' +
        'Circunferência abdominal (CA): *** mm.<br>' +
        'Comprimento umeral (CUM): *** mm.<br>' +
        'Comprimento femoral (CF): *** mm.<br>' +
        'Peso fetal em torno de *** gramas (+/- 15%).', blockID: 180, textTermID: 0, visivel: true
    },
    {
      value: '<b>ANATOMIA FETAL</b></br>' +
        'Crânio: **</br>' +
        'Sistema Nervoso Central: **</br>' +
        'Tórax: **</br>' +
        'Coração: **</br>' +
        'Parede Abdominal: **</br>' +
        'Abdome: **</br>' +
        'Aparelho Urinário: **</br>' +
        'Cordão Umbilical: **', blockID: 438, textTermID: 0, visivel: true
    },
    {
      value: '<b>PLACENTA</b><br>' +
        'Localização ***, com aspecto compatível com grau *** de Grannum.<br>' +
        'Espessura de *** mm.', blockID: 181, textTermID: 0, visivel: true
    },
    {
      value: '<b>CORDÃO UMBILICAL</b><br>' +
        'Inserção fetal e placentária preservadas. Presença de 2 artérias e 1 veia.', blockID: 306, textTermID: 0, visivel: true
    },
    {
      value: '<b>LÍQUIDO AMNIÓTICO</b><br>' +
        'Volume de líquido amniótico subjetivamente normal.', blockID: 183, textTermID: 0, visivel: true
    },
    { value: '', blockID: 308, textTermID: 0, visivel: true },
    {
      value: '<b>GENITÁLIA</b></br>' +
        'Compatível com o sexo ***.',
      blockID: 328, textTermID: 0, visivel: true
    },
  ];
  conclusoes: DescricaoConclusao[] = [
    { value: '', blockID: 179, textTermID: 0, visivel: true },
    { value: 'Gestação única, tópica e de concepto vivo.', blockID: 0, textTermID: 0, visivel: true },
    { value: '', blockID: 177, textTermID: 0, visivel: true },//bloco criado para a calculadora de idade gestacional
    { value: '', blockID: 180, textTermID: 0, visivel: true },
    { value: '', blockID: 438, textTermID: 0, visivel: true },
    { value: '', blockID: 183, textTermID: 0, visivel: true },
    { value: '', blockID: 328, textTermID: 0, visivel: true },
    { value: '', blockID: 181, textTermID: 0, visivel: true },
    { value: '', blockID: 306, textTermID: 0, visivel: true },
    { value: '', blockID: 178, textTermID: 0, visivel: true },
    { value: '', blockID: 308, textTermID: 0, visivel: true }
  ];

  genericFeto: TermosGenericos[] = [
    { value: '', selected: false, termID: 1980, term: '[situacao]' },
    { value: '', selected: false, termID: 1981, term: '[apresentacao]' },
    { value: '', selected: false, termID: 1982, term: '[dorso]' },
    { value: '', selected: false, termID: 1983, term: '[frequencia cardiaca]' },
    { value: '', selected: false, termID: 4168, term: '[polo cefalico]' },
    { value: '', selected: false, termID: 5570, term: '[SituacaoAusente]' },
    { value: '', selected: false, termID: 5571, term: '[ApresentacaoAusente]' },
    { value: '', selected: false, termID: 5572, term: '[DorsoAusente]' },
  ];

  genericAnatomiaFetal: TermosGenericos[] = [
    { value: '', selected: false, termID: 5651, term: '[Cranio]' },
    { value: '', selected: false, termID: 5652, term: '[CranioAlt]' },
    { value: '', selected: false, termID: 5653, term: '[Sistema]' },
    { value: '', selected: false, termID: 5654, term: '[SistemaAlt]' },
    { value: '', selected: false, termID: 5655, term: '[Torax]' },
    { value: '', selected: false, termID: 5656, term: '[ToraxAlt]' },
    { value: '', selected: false, termID: 5657, term: '[Coracao]' },
    { value: '', selected: false, termID: 5658, term: '[CoracaoAlt]' },
    { value: '', selected: false, termID: 5659, term: '[Parede]' },
    { value: '', selected: false, termID: 5660, term: '[ParedeAlt]' },
    { value: '', selected: false, termID: 5661, term: '[Abdome]' },
    { value: '', selected: false, termID: 5662, term: '[AbdomeAlt]' },
    { value: '', selected: false, termID: 5663, term: '[Aparelho]' },
    { value: '', selected: false, termID: 5664, term: '[AparelhoAlt]' },
    { value: '', selected: false, termID: 5665, term: '[Cordao]' },
    { value: '', selected: false, termID: 5666, term: '[CordaoAlt]' },
  ];

  genericPlacenta: TermosGenericos[] = [
    { value: '', selected: false, termID: 1996, term: '[localizacao]' },
    { value: '', selected: false, termID: 1997, term: '[grau]' },
    { value: '', selected: false, termID: 1998, term: '[espessura]' }
  ];

  genericCordaoUmbilical: TermosGenericos[] = [
    { value: '', selected: false, termID: 4706, term: '[InsercaoCordao]' }
  ];

  genericLiquido: TermosGenericos[] = [
    { value: '', selected: false, termID: 4780, term: '[MBV]' },
    { value: '', selected: false, termID: 2005, term: '[ILA]' }
  ];

  genericParametrosBiometricos: TermosGenericos[] = [
    { value: '', selected: false, termID: 1986, term: '[DBP]' },
    { value: '', selected: false, termID: 1987, term: '[DOF]' },
    { value: '', selected: false, termID: 1988, term: '[CC]' },
    { value: '', selected: false, termID: 1989, term: '[CA]' },
    { value: '', selected: false, termID: 1990, term: '[CUM]' },
    { value: '', selected: false, termID: 1991, term: '[CF]' },
    { value: '', selected: false, termID: 1992, term: '[peso]' },
    { value: '', selected: false, termID: 2035, term: '[semanas]' },
    { value: '', selected: false, termID: 2036, term: '[dias]' },
    { value: '', selected: false, termID: 4787, term: '[Percentil]' }
  ];

  genericUtero: TermosGenericos[] = [
    { value: '', selected: false, termID: 3951, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3952, term: '[Contornos]' },
    { value: '', selected: false, termID: 3953, term: '[Subtipo]' },
    { value: '', selected: false, termID: 3954, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3955, term: '[Medindo]' },
    { value: '', selected: false, termID: 3977, term: '[Ecogenicidade]' },
    { value: '', selected: false, termID: 3978, term: '[Contorno]' },
    { value: '', selected: false, termID: 3979, term: '[Subtipo]' },
    { value: '', selected: false, termID: 3980, term: '[Localizacao]' },
    { value: '', selected: false, termID: 3981, term: '[Medindo]' },
  ];

  genericGenitalia: TermosGenericos[] = [
    { value: '', selected: false, termID: 4743, term: '[Genitalia]' }
  ];

  public switchFormReport = true;
  editLaudoMode: boolean = false;
  impressaoAtivaParametrosBiometricos: any = false;
  impressaoAtivaPlacenta: any = false;
  impressaoAtivaLiquidoAmniotico: any = false;
  impressaoAtivaCalculadora: boolean = false;
  percentil!:any;

  constructor(private _selected: SelectedItemEmiter,
    private _copyPaste: CopyPaste,
    public dialog: MatDialog,
    private _toastr: ToastrManager,
    private _laudoService: LaudosService,
    private _validacoes: Validacoes,
    private _serviceMedicalReportLog: MedicalReportLogService,
    private _common: CommonService,
    private _pouchService: PouchDBService,
    private _serviceMedicalReport: MedicalReportService,
    private _config: ConfigLaudoService,
    private dataService:DataTermService,
    public _addText: AddText,
    public _controlImprimir:ControladorImprimirService
  ) { }

  ngOnInit() {
    this.utilizarEditor();
    this._toastr.warningToastr('Atenção, este exame passou por uma atualização para receber a última versão, pressione CTRL + SHIFT + DEL para excluir os dados de navegação e recarregue a página posteriormente. Para mais informações, entre em contato com nosso suporte.', 'Atualização realizada!',
    {
      position: 'top-right',
      toastTimeout: 8000,
      maxShown: 1
    });
    this._pouchService.loadDatabase("transv-seg-terc-trimestreAnalise", this.descricoes, this.conclusoes);
    this.resetaDialog();
    this.Subtitulo = 'Calculadora IG';
    this.subtituloBloco = 'Calculadora IG';
    // const CompanyID = +localStorage.getItem('companyID');
    // this._serviceMedicalReport.buscaMascara(this.medicalReportTypeID, CompanyID).subscribe((result) => {
    //   this.mask = result.mask;
    //   this.AdicionaTexto();
    // });
    const listBlocks = [
      this.uteroId,
      this.fetoId,
      this.parametrosBiometricosId,
      this.anatomiaFetalId,
      this.placentaId,
      this.cordaoUmbilicalId,
      this.orientacoesAdicionaisID,
      this.liquidoAmnioticoId,
      this.genitaliaId
    ];

    this._pouchService.loadMask(this.medicalReportTypeID, "transv-seg-terc-trimestreMask").then((obj: any)=> {
      this._serviceMedicalReport.criaLaudo(obj, false);
      this.mask = obj.mask;
      this.AdicionaTexto(this.newEditor);
      let loadLaudosRule = JSON.parse(localStorage.getItem('loadLaudosRule'));

      if (loadLaudosRule) {
        if (loadLaudosRule.active) {
          this._pouchService.createDatabase(listBlocks, "obsTransSegTerc");
        }
        if (!loadLaudosRule.active) {
          this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-obsteSegTerc');
        }
      }
      if (loadLaudosRule == null) {
        this._pouchService.loadLaudosDatabaseByBlocks(listBlocks, 'us-obsteSegTerc');
      }
    });
  }

  ngAfterViewInit(): void {
    this._controlImprimir.desabilitarImprimir();
  }

  switchEditor(): void {
    this.newEditor != true
      ? this.newEditor = true
      : this.newEditor = false;

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
    }, 100);
  }

  utilizarEditor(): void {
    var rule = JSON.parse(localStorage.getItem('ruleUpdated'));

    if (rule == null || rule == false) {
      this._config.buscarRules(localStorage.getItem('userID'))
        .then(configs => {
          configs.forEach(config => {
            if (config.medicalReportRuleID === 5 && config.active) {
              this.newEditor = true;
            } else {
              this.newEditor = false;
            }
          });
        }).catch(error => {
          console.log(error);
        });
    } else {
      this.newEditor = false;
    }
  }

  public resetaDialog(){
    this._common.invokeEvent.subscribe(event => {
      if(event){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'info|Deseja realmente limpar o formulário?'
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result){
            this.resetaLaudo();
            this._controlImprimir.desabilitarImprimir();
          }
        });
      }
    });
  };

  public resetaLaudo(){
    this._pouchService.getAll("transv-seg-terc-trimestreAnalise").then((obj)=>{
      var concCalc = 0;
      for (let i = 0; i < obj.length; i++) {
        if(obj[i].doc.value != "" || obj[i].doc.value != undefined){
          if(obj[i].doc.type == "descricao"){
            this.descricoes[i].value = obj[i].doc.value;
          } else if(obj[i].doc.type == "conclusao"){
            this.conclusoes[concCalc].value = obj[i].doc.value;
            concCalc++
          }
        }
      }
    });

    this.cabecalhoTexto = "";
    this.indicacaoTexto = "";
    this.showForm = false;
    this.achadosAdTexto = "";
    this.calcFrase = "";
    this.calcConclusao = "";
    this.rodapeTexto = "";

    setTimeout(() => {
      this.AdicionaTexto(this.newEditor);
      this.showForm = true;
    }, 500);
  };

  Subtitulo: string;
  subtituloBloco: string;
  htmlContent: string;
  mask: string;
  medicalReportTypeID = 36;
  term = new Term();
  injuries = new Injuries();

  termResultsAPI: Array<TermResult>;
  termConclusionAPI: Array<TermResult>;
  dinamicLesions: TermosDinamicos[] = [];
  dinamicLesion: any;
  lesionRow = 1;
  medicaoNodulo = '';
  indicacaoTexto = '';
  achadosAdTexto = '';
  calcFrase = '';
  calcConclusao = '';
  cabecalhoTexto = '';
  rodapeTexto = '';
  objetoGrupoLesao: any;
  cenarioCorrente = 0;

  cabecalho = false;
  showCalculadora = true;
  showUtero = false;
  showFeto = false;
  showParametrosBiometricos = false;
  showAnatomia = false;
  showPlacenta = false;
  showCordaoUmbilical = false;
  showLiquidoAmniotico = false;
  showGenitalia = false;
  achadosTexto = false;
  orientacoesAdicionais = false;
  indicacao = false;
  rodape = false;

  uteroId = 178;
  fetoId = 179;
  parametrosBiometricosId = 180;
  anatomiaFetalId = 438;
  placentaId = 181;
  cordaoUmbilicalId = 306;
  liquidoAmnioticoId = 183;
  orientacoesAdicionaisID = 308;
  genitaliaId = 328;
  public showForm: Boolean = true;
  public newEditor: boolean = false;
  public laudoCompleto: any = [];

  blocos = [
    {
      id: 'cabecalho',
      name: 'cabecalho',
      titulo: 'Informações',
      selected: false,
      blocked:false
    },
    {
      id: 'indicacao',
      name: 'indicacao',
      titulo: 'Indicação',
      subtitulo: 'Preencha o campo indicação',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Calculadora IG',
      id: 'calculadora',
      name: 'Calculadora',
      selected: true,
      blocked:true
    },
    {
      titulo: 'Útero',
      id: this.uteroId,
      name: 'Utero',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Feto',
      id: this.fetoId,
      name: 'Feto',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Biometria Fetal',
      id: this.parametrosBiometricosId,
      name: 'ParametrosBiometricos',
      selected: false,
      blocked:true
    },
    {
      titulo: 'Anatomia Fetal',
      id: this.anatomiaFetalId,
      name: 'AnatomiaFetal',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Placenta',
      id: this.placentaId,
      name: 'Placenta',
      selected: false,
      blocked:true
    },
    {
      titulo: 'Cordão Umbilical',
      id: this.cordaoUmbilicalId,
      name: 'CordaoUmbilical',
      selected: false,
      blocked:false
    },
    {
      titulo: 'Líquido Amniótico',
      id: this.liquidoAmnioticoId,
      name: 'LiquidoAmniotico',
      selected: false,
      blocked:true
    },
    {
      titulo: 'Genitália',
      id: this.genitaliaId,
      name: 'genitalia',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Orientações Adicionais',
      id: this.orientacoesAdicionaisID,
      name: 'orientacoes-adicionais',
      selected: false,
      blocked: false
    },
    {
      titulo: 'Assinatura',
      id: 'rodape',
      name: 'rodape',
      selected: false,
      blocked:false
    }
  ];

  selecionar(itemSelecionado) {
    const arrayAtualizado = this._selected.Selected(itemSelecionado.id, this.blocos);
    this.blocos = arrayAtualizado;
    this.Subtitulo = itemSelecionado.titulo;

    if(itemSelecionado.subtitulo)
      this.subtituloBloco = itemSelecionado.subtitulo;
    else
      this.subtituloBloco = itemSelecionado.titulo;

      if(this.editLaudoMode && Number.isInteger(itemSelecionado.id)){
        let seachTerm = this.listTermDefault.filter(item=> item.blockID===itemSelecionado.id);
        this.dataService.setTermos(seachTerm[0].listTerm,seachTerm[0].blockID);
      }

    this.cabecalho = (itemSelecionado.id === 'cabecalho');
    this.showCalculadora = (itemSelecionado.id === 'calculadora');
    this.showUtero = (itemSelecionado.id === this.uteroId);
    this.showFeto = (itemSelecionado.id === this.fetoId);
    this.showParametrosBiometricos = (itemSelecionado.id === this.parametrosBiometricosId);
    this.showAnatomia = (itemSelecionado.id === this.anatomiaFetalId);
    this.showPlacenta = (itemSelecionado.id === this.placentaId);
    this.showCordaoUmbilical = (itemSelecionado.id === this.cordaoUmbilicalId);
    this.showLiquidoAmniotico = (itemSelecionado.id === this.liquidoAmnioticoId);
    this.showGenitalia = (itemSelecionado.id === this.genitaliaId);
    this.indicacao = (itemSelecionado.id === 'indicacao');
    this.orientacoesAdicionais = (itemSelecionado.id === this.orientacoesAdicionaisID);
    this.rodape = (itemSelecionado.id === 'rodape');
  }

  RetornaConclusaoNull(Texto) {
    let texto = '';
    texto = 'Gestação única, tópica e de concepto vivo.';
    return texto;
  }

  RetornaConclusaoLista() {
    let texto = `<br/>
            <div style='margin-left: 20px;'><p><small>
              1. Este exame não tem o objetivo de analisar anatomia fetal detalhada. O exame morfológico deverá ser realizado para rastreamento de malformações e as principais síndromes cromossômicas, entre 20ª e 24ª semana de gestação;<br/>
              2. Alguns fatores como a estática fetal, a espessura do tecido subcutâneo e a idade gestacional podem limitar a acurácia do exame;<br/>
            </small></p></div>`;
    return texto;
  }

  limparTermos() {
    this.term.listTermID = new Array<number>();
    this.term.injuries = new Array<Injuries>();
    this.injuries.listInjuries = new Array<number>();

    this.genericFeto = [
      { value: '', selected: false, termID: 1980, term: '[situacao]' },
      { value: '', selected: false, termID: 1981, term: '[apresentacao]' },
      { value: '', selected: false, termID: 1982, term: '[dorso]' },
      { value: '', selected: false, termID: 1983, term: '[frequencia cardiaca]' },
      { value: '', selected: false, termID: 4168, term: '[polo cefalico]' },
      { value: '', selected: false, termID: 5570, term: '[SituacaoAusente]' },
      { value: '', selected: false, termID: 5571, term: '[ApresentacaoAusente]' },
      { value: '', selected: false, termID: 5572, term: '[DorsoAusente]' },
    ];

    this.genericAnatomiaFetal = [
      { value: '', selected: false, termID: 5651, term: '[Cranio]' },
      { value: '', selected: false, termID: 5652, term: '[CranioAlt]' },
      { value: '', selected: false, termID: 5653, term: '[Sistema]' },
      { value: '', selected: false, termID: 5654, term: '[SistemaAlt]' },
      { value: '', selected: false, termID: 5655, term: '[Torax]' },
      { value: '', selected: false, termID: 5656, term: '[ToraxAlt]' },
      { value: '', selected: false, termID: 5657, term: '[Coracao]' },
      { value: '', selected: false, termID: 5658, term: '[CoracaoAlt]' },
      { value: '', selected: false, termID: 5659, term: '[Parede]' },
      { value: '', selected: false, termID: 5660, term: '[ParedeAlt]' },
      { value: '', selected: false, termID: 5661, term: '[Abdome]' },
      { value: '', selected: false, termID: 5662, term: '[AbdomeAlt]' },
      { value: '', selected: false, termID: 5663, term: '[Aparelho]' },
      { value: '', selected: false, termID: 5664, term: '[AparelhoAlt]' },
      { value: '', selected: false, termID: 5665, term: '[Cordao]' },
      { value: '', selected: false, termID: 5666, term: '[CordaoAlt]' },
    ]

    this.genericPlacenta = [
      { value: '', selected: false, termID: 1996, term: '[localizacao]' },
      { value: '', selected: false, termID: 1997, term: '[grau]' },
      { value: '', selected: false, termID: 1998, term: '[espessura]' }

    ];

    this.genericCordaoUmbilical = [
      { value: '', selected: false, termID: 4706, term: '[InsercaoCordao]' }
    ];

    this.genericLiquido = [
      { value: '', selected: false, termID: 2005, term: '[ILA]' },
      { value: '', selected: false, termID: 4780, term: '[MBV]' }
    ];

    this.genericParametrosBiometricos = [
      { value: '', selected: false, termID: 1986, term: '[DBP]' },
      { value: '', selected: false, termID: 1987, term: '[DOF]' },
      { value: '', selected: false, termID: 1988, term: '[CC]' },
      { value: '', selected: false, termID: 1989, term: '[CA]' },
      { value: '', selected: false, termID: 1990, term: '[CUM]' },
      { value: '', selected: false, termID: 1991, term: '[CF]' },
      { value: '', selected: false, termID: 1992, term: '[peso]' },
      { value: '', selected: false, termID: 2035, term: '[semanas]' },
      { value: '', selected: false, termID: 2036, term: '[dias]' },
      { value: '', selected: false, termID: 4787, term: '[Percentil]' }
    ];

    this.genericUtero = [
      { value: '', selected: false, termID: 3951, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3952, term: '[Contornos]' },
      { value: '', selected: false, termID: 3953, term: '[Subtipo]' },
      { value: '', selected: false, termID: 3954, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3955, term: '[Medindo]' },
      { value: '', selected: false, termID: 3977, term: '[Ecogenicidade]' },
      { value: '', selected: false, termID: 3978, term: '[Contorno]' },
      { value: '', selected: false, termID: 3979, term: '[Subtipo]' },
      { value: '', selected: false, termID: 3980, term: '[Localizacao]' },
      { value: '', selected: false, termID: 3981, term: '[Medindo]' },
    ];

    this.genericGenitalia = [
      { value: '', selected: false, termID: 4743, term: '[Genitalia]' }
    ];
  }

  enviaLesoes($event) {
    if (this.cenarioCorrente == 0) {
      this.cenarioCorrente = $event.cenario;
    }

    if ($event.cenario == 0 || $event.cenario == this.cenarioCorrente) {
      //Adiciona ao objeto
      this.injuries.listInjuries.push($event.id);

    } else {
      //adiciona objeto à lista
      const injuryToAdd = new Injuries();
      injuryToAdd.listInjuries = new Array<number>();

      this.injuries.listInjuries.forEach(obj => {
        injuryToAdd.listInjuries.push(obj);
      });

      if (injuryToAdd.listInjuries.length > 0)
      this.term.injuries.push(injuryToAdd);

      // cria outro objeto e adiciona na lista
      this.injuries.listInjuries = new Array<number>();
      this.injuries.listInjuries.push($event.id);

      this.cenarioCorrente = $event.cenario;
    }
  }

  incluirLesoesDinamicas($event) {
    const lesoesValue = this._validacoes.formataDecimal($event.elemento.value);

    if ($event.elemento.id == 3981 && $event.posicao > 0 && lesoesValue != '') {
      if (this.medicaoNodulo == '' || $event.posicao == 1) {
        this.medicaoNodulo = lesoesValue;
        this.dinamicLesion = {
          value: lesoesValue,
          cenario: $event.elemento.cenario,
          selected: true,
          termID: 3981,
          lesionID: 3974,
          linha: $event.linha,
          posicao: $event.posicao,
          term: '[Medindo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
      } else {
        this.medicaoNodulo = this.medicaoNodulo + ' x ' + lesoesValue;
        this.dinamicLesions.forEach(obj => {
          if (obj.termID == $event.elemento.id && obj.linha == $event.linha) {
            obj.value = this.medicaoNodulo;
            obj.selected = true;
            obj.termID = 3981;
            obj.lesionID = 3974;
            obj.cenario = $event.elemento.cenario;
            obj.linha = $event.linha;
            obj.posicao = $event.posicao;
            obj.term = '[Medindo]';

          }
        });
      }
    }

    if ($event.linha == 1 && ($event.posicao == 0 || $event.posicao == 1)) {
      this.genericUtero.forEach(obj => {
        if (obj.termID == $event.elemento.id) {
          obj.selected = true;
          obj.value = lesoesValue;
        }
      });
      if ($event.isPrimeiraLesao == true) {
        this.term.listTermID.push($event.elemento.id);
      } else {
        this.enviaLesoes($event.elemento);
      }
    }

    switch ($event.elemento.id) {
      //NODULO
      case '3977':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3977,
          lesionID: 3974,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Ecogenicidade]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3978':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3978,
          lesionID: 3974,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Contorno]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3979':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3979,
          lesionID: 3974,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Subtipo]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
      case '3980':
        this.dinamicLesion = {
          value: lesoesValue,
          selected: true,
          termID: 3980,
          lesionID: 3974,
          linha: $event.linha,
          posicao: 0,
          cenario: $event.elemento.cenario,
          term: '[Localizacao]'
        };
        this.dinamicLesions.push(this.dinamicLesion);
        break;
    }

    this.lesionRow = $event.linha;
  }

  consultarAPI() {
    if(this.editLaudoMode){
      let blocoSelecionado = this.blocos.filter(bloco=>{
        return bloco.selected==true
      })
      this.dataService.setTermos(this.term.listTermID,blocoSelecionado[0].id)
      return;
    } else if(!this.editLaudoMode){
      let desc;
      if (this.showUtero) {
        this.term.blockID = this.uteroId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.uteroId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoUtero(resultado.text, contadorTexto);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.uteroId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.uteroId,
            textTermID: 0
          };
          let espaco = '';
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.blockID = this.uteroId;
              obj.value = obj.value + espaco + this.MontaConclusaoUtero(resultado.conclusion);
              espaco = '</br>';
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.uteroId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.uteroId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
      else if (this.showFeto) {
        this.term.blockID = this.fetoId;

        this.term.injuries.push(this.injuries);

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.fetoId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoFeto(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.fetoId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.fetoId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoFeto(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.fetoId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.fetoId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if(this.showAnatomia){
        this.term.blockID = this.anatomiaFetalId;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.anatomiaFetalId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                debugger
                if (resultado.text) {
                  obj.value = obj.value + espaco + this.MontaDescricaoAnatomiaFetal(resultado.text, contadorTexto);
                  espaco = '<br/>';
                }
                contadorTexto++;
              });

              const index = this.descricoes.findIndex(x => x.blockID === this.anatomiaFetalId);
              if (index > -1) {
                this.descricoes[index].value = '<b>ANATOMIA FETAL</b></br>' + obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }

            }
          });
          let obj = {
            value: '',
            blockID: this.anatomiaFetalId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          // let hasConclusion = false;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoAnatomiaFetal(resultado.conclusion, contadorTexto);
              espaco = '</br>';
              // hasConclusion = true;
            } else {
              if (indexConclusion.length <= 0) {
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.value = '';
                }
              }
            }
            contadorTexto++;
          });
          // if (!hasConclusion) {
          //   obj.value = 'Avaliação da morfologia fetal dentro da normalidade para atual idade gestacional.';
          // }

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.anatomiaFetalId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.anatomiaFetalId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.anatomiaFetalId,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showPlacenta) {
        this.term.blockID = this.placentaId;

        this.term.injuries.push(this.injuries);

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.placentaId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + this.MontaDescricaoPlacenta(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.placentaId);
              if (index > -1) {
                this.descricoes[index].value = '<b>Placenta:</b><br>' + obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.placentaId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoPlacenta(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.placentaId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.placentaId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showCordaoUmbilical) {
        this.term.blockID = this.cordaoUmbilicalId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.cordaoUmbilicalId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + this.MontaDescricaoCordaoUmbilical(resultado.text);
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.cordaoUmbilicalId);
              if (index > -1) {
                if (obj.value)
                  this.descricoes[index].value = '<b>Cordão umbilical:</b></br>' + obj.value;
                else
                  this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });
          let obj = {
            value: '',
            blockID: this.cordaoUmbilicalId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = resultado.conclusion;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.cordaoUmbilicalId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.cordaoUmbilicalId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showLiquidoAmniotico) {
        this.term.blockID = this.liquidoAmnioticoId;

        this.term.injuries.push(this.injuries);

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.liquidoAmnioticoId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = '<b>Líquido amniótico:</b><br>' + obj.value + espaco + this.MontaDescricaoLiquido(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.liquidoAmnioticoId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.liquidoAmnioticoId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoLiquido(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.liquidoAmnioticoId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.liquidoAmnioticoId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showParametrosBiometricos) {
        this.term.blockID = this.parametrosBiometricosId;

        this.term.injuries.push(this.injuries);

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.parametrosBiometricosId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              let contadorTexto = 0;
              this.termResultsAPI.forEach(resultado => {
                obj.value = '<b>Biometria Fetal: </b></br>' + obj.value + espaco + this.MontaDescricaoParametros(resultado.text);
                espaco = '</br>';
                contadorTexto++;
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.parametrosBiometricosId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });

          let obj = {
            value: '',
            blockID: this.parametrosBiometricosId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoParametros(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.parametrosBiometricosId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.parametrosBiometricosId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if(!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.orientacoesAdicionais) {
        this.term.blockID = this.orientacoesAdicionaisID;

        this.term.injuries.push(this.injuries);
        this.cenarioCorrente++;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.orientacoesAdicionaisID && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + espaco + resultado.text;
                espaco = '</br>';
              });

              let index = this.descricoes.findIndex(x => x.blockID === this.orientacoesAdicionaisID);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
            }
          });
          let obj = {
            value: '',
            blockID: this.orientacoesAdicionaisID,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + resultado.conclusion;
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.orientacoesAdicionaisID);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.orientacoesAdicionaisID) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: +this.orientacoesAdicionaisID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      } else if (this.showGenitalia) {
        this.term.blockID = this.genitaliaId;

        this._pouchService.getLaudos(this.term).then((termResults) => {
          this.descricoes.forEach(obj => {
            if (obj.blockID === this.genitaliaId && termResults[0] !== undefined) {
              this.termResultsAPI = termResults;
              obj.textTermID = this.termResultsAPI[0].id;
              obj.value = '';
              let espaco = '';
              this.termResultsAPI.forEach(resultado => {
                obj.value = obj.value + this.MontaDescricaoGenitalia(resultado.text);
                contadorTexto++;
              });

              const index = this.descricoes.findIndex(x => x.blockID === this.genitaliaId);
              if (index > -1) {
                this.descricoes[index].value = obj.value;
                this.descricoes[index].textTermID = obj.textTermID;
                desc = obj.value;
              }
              // this.AdicionaTexto();
            }
          });
          let obj = {
            value: '',
            blockID: this.genitaliaId,
            textTermID: 0
          };
          const indexConclusion = termResults.filter(x => x.conclusion !== null);
          this.termConclusionAPI = termResults;
          obj.value = '';
          let espaco = '';
          let contadorTexto = 0;
          this.termConclusionAPI.forEach(resultado => {
            if (resultado.conclusion) {
              obj.value = obj.value + espaco + this.MontaConclusaoGenitalia(resultado.conclusion);
              espaco = '</br>';
              contadorTexto++;
            } else {
              if (indexConclusion.length <= 0) {
                obj.blockID = 0;
                obj.value = this.RetornaConclusaoNull(resultado.conclusion);
              } else {
                if (!obj.value) {
                  obj.blockID = 0;
                  obj.value = '';
                }
              }
            }
          });

          const conclusaoAll = this.conclusoes.filter(x => x.value !== '');
          const conclusaoNull = this.conclusoes.find(x => x.blockID === 0);
          const conclusao = this.conclusoes.find(x => x.blockID === this.genitaliaId);

          if (obj.blockID === 0) {
            if (conclusaoAll.length <= 1) {
              if (conclusaoAll[0].blockID === 0 || conclusaoAll[0].blockID === this.genitaliaId) {
                conclusaoNull.value = obj.value;
                conclusao.value = '';
              } else {
                conclusaoNull.value = '';
              }
            } else {
              conclusao.value = '';
            }
          } else if (obj.value) {
            conclusaoNull.value = '';
            conclusao.value = obj.value;
          }

          if (!this.newEditor) {
            this.AdicionaTexto(this.newEditor);
          } else {
            this.atualizaLinha({
              blockId: this.term.blockID,
              descricao: desc,
              conclusao: conclusao.value,
              conclusaoNull: conclusaoNull.value
            });
          }
        });
      }
    }
  }

  enviaTermos($event) {
    if ($event == 'Clean') {
      this.limparTermos();
    } else {
      this.term.listTermID.push($event);
    }
  }

  enviaCabecalho(texto) {
    if (texto)
      this.cabecalhoTexto = texto;
    else
      this.cabecalhoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'cabecalho',
        cabecalho: this.cabecalhoTexto
      });
    }
  }

  enviaIndicacao(texto) {
    if (texto)
      this.indicacaoTexto = '<b>Indicação:</b><br/>' + texto + '<br/><br/>';
    else
      this.indicacaoTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'indicacao',
        indicacao: this.indicacaoTexto
      });
    }
  }

  enviaAchados(texto) {
    if (texto)
      this.achadosAdTexto = texto;
    else
      this.achadosAdTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: this.orientacoesAdicionaisID,
        achados: this.achadosAdTexto
      });
    }
  }

  enviaRodape(texto) {
    if (texto) {
      this.rodapeTexto = texto;
    } else
      this.rodapeTexto = '';

    if(!this.newEditor) {
      this.AdicionaTexto(this.newEditor);
    } else {
      this.atualizaLinha({
        blockId: 'rodape',
        rodape: this.rodapeTexto
      });
    }
  }

  adicionaSelecaoUtero($event) {
    this.genericUtero.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoFeto($event) {
    this.genericFeto.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoAnatomiaFetal($event){
    this.genericAnatomiaFetal.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    if ($event.isPrimeiraLesao == true) {
      this.term.listTermID.push($event.elemento.id);
    } else {
      this.objetoGrupoLesao = { id: $event.elemento.id, cenario: 0 };
      this.enviaLesoes(this.objetoGrupoLesao);
    }
  }

  adicionaSelecaoPlacenta($event) {
    this.genericPlacenta.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoCordaoUmbilical($event) {
    this.genericCordaoUmbilical.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }


  adicionaSelecaoLiquido($event) {
    this.genericLiquido.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoParametros($event) {
    this.genericParametrosBiometricos.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        if (+$event.elemento.id === 2035 || +$event.elemento.id === 2036)
          obj.value = $event.elemento.value;
        else
          obj.value = this._validacoes.formataDecimal($event.elemento.value);
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  adicionaSelecaoGenitalia($event) {
    this.genericGenitalia.forEach(obj => {
      if (obj.termID == $event.elemento.id) {
        obj.selected = true;
        obj.value = $event.elemento.value;
      }
    });
    this.term.listTermID.push($event.elemento.id);
  }

  MontaDescricaoFeto(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1980 || +term === 1981 || +term === 1982 || +term === 1983 ||
          +term === 4168 || +term === 5570 || +term === 5571 || +term === 5572) {
        this.genericFeto.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoFeto(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1980 || +term === 1981 || +term === 1982 || +term === 1983 ||
          +term === 4168 || +term === 5570 || +term === 5571 || +term === 5572) {
        this.genericFeto.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            //Apresentacao
            if(+term === 1981){
              if(generic.value === 'pélvica') {
                textoRetorno = textoRetorno.slice(0, -1) + ' com apresentação pélvica.';
              } else if(generic.value === 'córmica') {
                textoRetorno = textoRetorno.slice(0, -1) + ' com apresentação córmica.';
              }
            }
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoUtero(texto, contadorTexto) {
    const rowDinamic = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return (din.cenario - 1) == contadorTexto;
    });

    if (rowDinamic != undefined && rowDinamic.length > 0) {
      const lesaoID = rowDinamic[0].lesionID;
      texto = this.TextoUteroDescDinamica(texto, contadorTexto, lesaoID);
    } else {
      texto = this.TextoUteroDescNormal(texto, contadorTexto);
    }
    return texto;
  }

  TextoUteroDescNormal(texto, contadorTexto) {
    let textoRetorno = '';

    if (contadorTexto == 0) {
      this.term.listTermID.forEach(term => {
        if (+term === 3951 || +term === 3952 || +term === 3953 || +term === 3954 || +term === 3955) {
          this.genericUtero.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });

    }
    return texto;
  }

  TextoUteroDescDinamica(texto, contadorTexto, lesaoID) {
    const textoSplit = texto.split('|', 2);
    let textoRetorno = '';
    let textoDinamico = '';
    let linha = 1;

    if (textoSplit.length == 1) {
      textoDinamico = textoSplit[0];
    } else {
      textoRetorno = textoSplit[0] + '</br>';
      textoDinamico = textoSplit[1];
    }

    this.term.listTermID.forEach(term => {
      if (+term === 3977 || +term === 3978) {
        this.genericUtero.forEach(generic => {
          if (+generic.termID === +term) {
            textoRetorno = textoRetorno.replace(generic.term, generic.value);
          }
        });
      }
    });

    this.dinamicLesions.forEach(item => {
      if (lesaoID == item.lesionID) {
        if (item.linha == linha) {
          if (item.termID === 3977 || item.termID === 3978 || item.termID == 3979
            || item.termID == 3980 || item.termID == 3981) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        } else {
          linha++;
          textoRetorno = textoRetorno + textoDinamico;

          if (textoSplit.length == 1) {
            textoDinamico = textoSplit[0];
          } else if (item.linha >= 2) {
            textoDinamico = '</br>' + textoSplit[1];
          } else {
            textoDinamico = textoSplit[1];
          }

          if (item.termID === 3977 || item.termID === 3978 || item.termID == 3979
            || item.termID == 3980 || item.termID == 3981) {
            textoDinamico = textoDinamico.replace(item.term, item.value);
          }
        }
      }
    });
    textoRetorno = textoRetorno + textoDinamico;
    texto = textoRetorno;

    this.dinamicLesions = this.dinamicLesions.filter((din) => { // Validar a linha da lesão dinamica
      return lesaoID != din.lesionID;
    });

    return texto;
  }

  MontaConclusaoUtero(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 3951 || +term === 3952 || +term === 3953 || +term === 3954 || +term === 3955 ||
        +term === 3977 || +term === 3978 || +term === 3979 || +term === 3980 || +term === 3981) {
        this.genericUtero.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaDescricaoAnatomiaFetal(texto, contadorTexto){
    let textoRetorno = '';
    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (+term === 5651 || +term === 5652 || +term === 5653 || +term === 5654
          || +term === 5655 || +term === 5656 || +term === 5657 || +term === 5658
          || +term === 5659 || +term === 5660 || +term === 5661 || +term === 5662
          || +term === 5663 || +term === 5664 || +term === 5665 || +term === 5666) {
          this.genericAnatomiaFetal.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 5651 || +term === 5652 || +term === 5653 || +term === 5654
          || +term === 5655 || +term === 5656 || +term === 5657 || +term === 5658
          || +term === 5659 || +term === 5660 || +term === 5661 || +term === 5662
          || +term === 5663 || +term === 5664 || +term === 5665 || +term === 5666) {
          this.genericAnatomiaFetal.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaConclusaoAnatomiaFetal(texto, contadorTexto){
    let textoRetorno = '';

    if (contadorTexto == 0) { // Caso seja a primeira lesão
      this.term.listTermID.forEach(term => {
        if (+term === 5651 || +term === 5652 || +term === 5653 || +term === 5654
          || +term === 5655 || +term === 5656 || +term === 5657 || +term === 5658
          || +term === 5659 || +term === 5660 || +term === 5661 || +term === 5662
          || +term === 5663 || +term === 5664 || +term === 5665 || +term === 5666) {
          this.genericAnatomiaFetal.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    } else { // Caso seja uma lesão adicional (da lista de injuries)
      this.term.injuries[contadorTexto - 1].listInjuries.forEach(term => {
        if (+term === 5651 || +term === 5652 || +term === 5653 || +term === 5654
          || +term === 5655 || +term === 5656 || +term === 5657 || +term === 5658
          || +term === 5659 || +term === 5660 || +term === 5661 || +term === 5662
          || +term === 5663 || +term === 5664 || +term === 5665 || +term === 5666) {
          this.genericAnatomiaFetal.forEach(generic => {
            if (+generic.termID === +term) {
              generic.selected = true;
              textoRetorno = texto.replace(generic.term, generic.value);
              texto = textoRetorno;
            }
          });
        }
      });
    }
    return texto;
  }

  MontaDescricaoPlacenta(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1996 || +term === 1997 || +term === 1998) {
        this.genericPlacenta.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoCordaoUmbilical(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4703 || +term === 4704 || +term === 4705 || +term === 4706) {
        this.genericCordaoUmbilical.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoPlacenta(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1996 || +term === 1997 || +term === 1998) {
        this.genericPlacenta.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoLiquido(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4780 || +term === 2005) {
        this.genericLiquido.forEach(generic => {
          debugger
          if (+generic.termID === +term) {
            generic.selected = true;

            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaConclusaoLiquido(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4780 || +term === 2005) {
        this.genericLiquido.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  MontaDescricaoParametros(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1986 || +term === 1987 || +term === 1988 || +term === 1989
        || +term === 1990 || +term === 1991 || +term === 1992 || +term === 2035 || +term === 2036 || +term === 4787) {
        this.genericParametrosBiometricos.forEach(generic => {
          if (+generic.termID === +term) {
            if(+term === 4787){
              this.handleGetPercentil(generic.value);
            }
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    return texto;
  }

  handleGetPercentil(value){
    let number = value.replace(',','.');
    this.percentil =  Number(number);
  }

  MontaConclusaoParametros(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 1986 || +term === 1987 || +term === 1988 || +term === 1989
        || +term === 1990 || +term === 1991 || +term === 1992 || +term === 2035 || +term === 2036 || +term === 4787) {
        this.genericParametrosBiometricos.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });

    if(this.percentil < 3){
      if(!texto.includes('<br> Restrição de crescimento fetal grave para idade gestacional segundo Hadlock IV.')){
        texto = "Restrição de crescimento fetal para idade gestacional. <br>" + texto;
      }
    } else if(this.percentil >= 3 && this.percentil < 10){
      if(!texto.includes('<br> Restrição de crescimento fetal para idade gestacional segundo Hadlock IV.')){
        texto = "Crescimento fetal pequeno para idade gestacional. <br>" + texto;
      }
    }else if (this.percentil >= 10 && this.percentil <= 90){
      if(!texto.includes('<br> Crescimento fetal adequado para a idade gestacional segundo Hadlock IV.')){
        texto = "Crescimento fetal adequado para a idade gestacional. <br>" + texto;
      }
    } else if(this.percentil > 90){
      if(!texto.includes('<br> Crescimento fetal adequado para a idade gestacional segundo Hadlock IV.')){
        texto = "Crescimento fetal grande para idade gestacional. <br>" + texto;
      }
    }
    return texto;
  }

  MontaDescricaoGenitalia(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4743) {
        this.genericGenitalia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  MontaConclusaoGenitalia(texto) {
    let textoRetorno = '';

    this.term.listTermID.forEach(term => {
      if (+term === 4743) {
        this.genericGenitalia.forEach(generic => {
          if (+generic.termID === +term) {
            generic.selected = true;
            textoRetorno = texto.replace(generic.term, generic.value);
            texto = textoRetorno;
          }
        });
      }
    });
    return texto;
  }

  enviaCalculadora($event) {
    if($event[0] !== undefined){
      this.calcFrase = $event[0].toString();
      this.AdicionaTexto(this.newEditor);
    }else{
      this.calcFrase = '';
      this.AdicionaTexto(this.newEditor);
    }
  }

  enviaCalculadoraConclusao($event) {
    if($event[0] !== undefined){
      this.calcConclusao = $event[0].toString();
      this.AdicionaTexto(this.newEditor);
    }
  }

  AdicionaTexto(isNewEditor: boolean) {
    if(isNewEditor)
      this.adicionaTextoNewEditor();
    else
      this.adicionaTextoOldEditor();

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoNewEditor() {
    let maskSplit = this.mask.split('|');
    this.laudoCompleto = [];

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-cabecalho`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.cabecalhoTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[0]}</p>`,
      }
    });
    if(this.calcFrase){
      this.laudoCompleto.push({
        'type': 'paragraph',
        'blockMenu': {
          'id': `e-calculadora`,
          'name': ''
        },
        'data': {
          'text': `<p>${this.calcFrase}</p>`,
        }
      });
    }
    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[1]}</p>`,
      }
    });


    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-indicacao`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.indicacaoTexto}</p>`,
      }
    });

    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active) {
      this.laudoCompleto.push({
      'type': 'paragraph',
        'data': {
          'text': '</br><b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>',
        }
      });
    }

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[2]}</p>`,
      }
    });

    this.descricoes.forEach(descricao => {
      if (descricao.value && descricao.value !== '' && descricao.blockID !== 308) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-${descricao.blockID}`,
            'name': ''
          },
          'data': {
            'text': `<p>${descricao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'data': {
        'text': `<p>${maskSplit[3]}</p>`,
      }
    });


    this.conclusoes.forEach((conclusao) => {
      if (conclusao.blockID == 177 && this.calcConclusao) {
        conclusao.value = this.calcConclusao;
      }
      if (conclusao.value !== '' ) {
        this.laudoCompleto.push({
          'type': 'paragraph',
          'blockMenu': {
            'id': `e-conclusao`
          },
          'data': {
            'text': `<p>${conclusao.value}</p>`,
          }
        });
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-observacao`
      },
      'data': {
        'text': `<p>${this.RetornaConclusaoLista()}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-achados`,
        'name': ''
      },
      'data': {
        'text': `<p>${this.achadosAdTexto}</p>`,
      }
    });

    this.laudoCompleto.push({
      'type': 'paragraph',
      'blockMenu': {
        'id': `e-rodape`,
        'name': ''
      },
      'data': {
        'text': `<p><div style="text-align: right;">${this.rodapeTexto}</div><br/></p>`,
      }
    });

    this._common.atualizaEditorJs(this.laudoCompleto);

    setTimeout(() => {
      this._addText.lineHeight();
    }, 500);
  }

  adicionaTextoOldEditor() {
    let i;
    let maskSplit = this.mask.split('|');

    if (this.cabecalhoTexto !== '') {
      this.htmlContent = this.cabecalhoTexto;
      this.htmlContent += maskSplit[0];
    } else {
      this.htmlContent = maskSplit[0];
    }

    this.htmlContent +=  this.calcFrase;
    this.htmlContent += maskSplit[1];
    this.htmlContent += this.indicacaoTexto;
    //Lógica Rules
    if (localStorage.getItem('tecnicaRule') && JSON.parse(localStorage.getItem('tecnicaRule')).active)
      this.htmlContent += '<b>Técnica:</b></br>Exame realizado com transdutor convexo multifrequencial.</br></br>';

    this.htmlContent += maskSplit[2];

    for (i = 0; i < this.descricoes.length; i++) {
      if (this.descricoes[i].value && this.descricoes[i].blockID !== 308) {
        this.htmlContent += '<br/>' + this.descricoes[i].value + '<br/>';
      }
    }
    this.htmlContent += maskSplit[3];

    debugger
    for (i = 0; i < this.conclusoes.length; i++) {
      if((this.conclusoes[i].blockID == this.anatomiaFetalId) && this.conclusoes[i].value == 'Gestação única, tópica e de concepto vivo.'){
        this.conclusoes[i].visivel = false;
      } else{
        this.conclusoes[i].visivel = true
      }
      if (this.conclusoes[i].blockID == 177 && this.calcConclusao && this.conclusoes[i].visivel)
        this.htmlContent += '<br/>' + this.calcConclusao;
      if (this.conclusoes[i].value && this.conclusoes[i].visivel) {
          this.htmlContent += '<br/>' + this.conclusoes[i].value ;
      }
    }

    //Orientação Adcional
    if (this.descricoes[7].blockID == 308 && this.descricoes[7].value !== '') {
      this.htmlContent += maskSplit[4];
      this.htmlContent += 'Sugiro, de forma complementar:<br/>' + this.descricoes[7].value + '<br/ > ';
      this.htmlContent += this.achadosAdTexto;
    } else if (this.achadosAdTexto !== '') {
      this.htmlContent += maskSplit[4];
      this.htmlContent += this.achadosAdTexto;
    }

    this.htmlContent += this.RetornaConclusaoLista();

    if(this.rodapeTexto != '')
      this.htmlContent += this.rodapeTexto;
  }

  atualizaLinha({
		blockId,
		descricao,
		conclusao,
    conclusaoNull,
		indicacao,
		achados,
    cabecalho,
    rodape,
    calculadora,
    tabela
	}: IAtualizaLinha): void {
    if(blockId !== undefined) {
			if(indicacao === undefined && achados === undefined) {
				if(descricao === undefined) {
					this.descricoes.forEach(desc => {
						if (desc.blockID === blockId) {
							descricao = desc.value;
						}
					});
				}

				const description = document.querySelector(`#e-${blockId} p`);
        const conclusion = document.querySelector('#e-conclusao p');

				if(conclusao !== '' || conclusaoNull !== '') {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
				} else {
          if(conclusion) {
            conclusion.innerHTML = '';

            this.conclusoes.forEach((conc, index) => {
              if (conc.value !== '') {
                conclusao = `<p>${conc.value}</p>`;

                conclusion.innerHTML += conclusao === ''
                    ? conclusaoNull !== ''
                      ? conclusaoNull
                      : ''
                    : conclusao;
              }
            });
          }
        }

        if(description) {
          description.innerHTML = descricao;
        } else if(!description) {
          this.AdicionaTexto(true);
        } else if(!description && !conclusion) {
          this.AdicionaTexto(true);
        }

        if(cabecalho !== undefined) {
          const header = document.querySelector(`#e-${blockId}`);

          if(header) {
						header.innerHTML = cabecalho;
          }
        }

        if(rodape !== undefined) {
          const footer = document.querySelector(`#e-${blockId}`);

          if(footer) {
						footer.innerHTML = rodape;
          }
        }

        if(calculadora !== undefined) {
          const calculo = document.querySelector(`#e-${blockId}`);

          if(calculo) {
						calculo.innerHTML = calculadora;
          }
        }

        if(tabela !== undefined) {
          const table = document.querySelector(`#e-${blockId}`);

          if(table) {
						table.innerHTML = tabela;
          }
        }
			} else if(indicacao !==undefined || achados !== undefined) {
				if(indicacao !== undefined) {
          const recommendation = document.querySelector(`#e-${blockId} p`);

          if(recommendation) {
            recommendation.innerHTML = indicacao;
          } else {
            this.AdicionaTexto(true);
          }
				} else if(achados !== undefined) {
          const findings = document.querySelector(`#e-${blockId} p`);

          if(findings) {
						findings.innerHTML = achados;
          } else {
            this.AdicionaTexto(true);
          }
				}
      }
			this._addText.lineHeight();
    }
  }

  enviarMrID() {
    return this.medicalReportTypeID;
  }

  auditoriaUsuario() {
    const obj = {
      UserID: +localStorage.getItem('userID'),
      MedicalReportTypeID: this.medicalReportTypeID,
      ContentReport: this._copyPaste.getTextFromId('editor'),
      MedicalReportLogTypeID: 1
    };

    if (obj)
      this._serviceMedicalReportLog.saveLogMedicalReport(obj);
  }

  handleSwitchFormReport(formReport: string) {
    if(formReport == 'form') {
      this.switchFormReport = true;
    } else {
      this.switchFormReport = false;
    }
  }

  ativarImpressaoParametrosBiometricos(event){
    if(event.ativo){
      this.impressaoAtivaParametrosBiometricos=true;
      this.blocos[5].blocked=false;
    }else{
      this.impressaoAtivaParametrosBiometricos=false;
      this.blocos[5].blocked=true;
    }
    this.ativarImpressao();
  }

  ativarImpressaoPlacenta(event){
    if(event.ativo){
      this.impressaoAtivaPlacenta=true;
      this.blocos[6].blocked=false;
    }else{
      this.impressaoAtivaPlacenta=false;
      this.blocos[6].blocked=true;
    }
    this.ativarImpressao();
  }
  ativarImpressaoLiquidoAmniotico(event){
    if(event.ativo){
      this.impressaoAtivaLiquidoAmniotico=true;
      this.blocos[8].blocked=false;
    }else{
      this.impressaoAtivaLiquidoAmniotico=false;
      this.blocos[8].blocked=true;
    }
    this.ativarImpressao();
  }

  ativarImpressaoCalculadora($event) {
    if ($event.ativo) {
      this.impressaoAtivaCalculadora = true;
      this.blocos[2].blocked = false;
    } else {
      this.impressaoAtivaCalculadora = false;
      this.blocos[2].blocked = true;
    }
    this.ativarImpressao();
  }

  ativarImpressao(){
    if(this.impressaoAtivaParametrosBiometricos && this.impressaoAtivaPlacenta && this.impressaoAtivaLiquidoAmniotico && this.impressaoAtivaCalculadora)
      this._controlImprimir.habilitarImprimir();
    else
    this._controlImprimir.desabilitarImprimir();
  }
}
