import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-orientacoes-adicionais',
  templateUrl: './orientacoes-adicionais.component.html',
  styleUrls: ['./orientacoes-adicionais.component.css']
})
export class OrientacoesAdicionaisComponent implements OnInit {
  
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();

  // @ViewChild('inOrientAdcional') inOrientAdcional: ElementRef;
  @ViewChild('ckUltrassonografia') ckUltrassonografia: MatCheckbox;
  @ViewChild('ckDopplerObstetrico') ckDopplerObstetrico: MatCheckbox;
  @ViewChild('ckEcocardiografia') ckEcocardiografia: MatCheckbox;

  countLesoes = 0;

  constructor() { }

  ngOnInit() {
  }

  clickDefault() {
    this.catchIDs();
  }

  catchIDs(){
    this.countLesoes = 0;
    this.termoSelecionado.emit('Clean');
    
      this.emitUtrassonografia();
      
      if(this.countLesoes > 0){
        this.emitDopplerObstetricoLesao();
      } else {
        this.emitDopplerObstetrico();
      }
      if(this.countLesoes > 0){
        this.emitEcocardiografiaLesao();
      } else {
        this.emitEcocardiografia();
      }
      this.emitTermVazio();
    this.listaCompleta.emit();    
  }

  emitUtrassonografia() {
    if(this.ckUltrassonografia && this.ckUltrassonografia.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckUltrassonografia.id);
    }
  }

  emitUtrassonografiaLesao() {
    if (this.ckUltrassonografia && this.ckUltrassonografia.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckUltrassonografia.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitDopplerObstetrico() {
    if(this.ckDopplerObstetrico && this.ckDopplerObstetrico.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckDopplerObstetrico.id);
    }
  }

  emitDopplerObstetricoLesao() {
    if (this.ckDopplerObstetrico && this.ckDopplerObstetrico.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckDopplerObstetrico.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);
    }
  }

  emitEcocardiografia() {
    if(this.ckEcocardiografia && this.ckEcocardiografia.checked){
      this.countLesoes++;
      this.termoSelecionado.emit(this.ckEcocardiografia.id);
    }
  }

  emitEcocardiografiaLesao() {
    if (this.ckEcocardiografia && this.ckEcocardiografia.checked) {
      this.countLesoes++;
      const objeto = {id: this.ckEcocardiografia.id, cenario: this.countLesoes};
      this.lesoesSelecionadas.emit(objeto);
    }
  }
  emitTermVazio(){
    if(!this.ckEcocardiografia.checked && !this.ckDopplerObstetrico.checked
      && !this.ckUltrassonografia.checked){
        this.termoSelecionado.emit(4398);
      }
  }

}
