import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';

@Component({
  selector: 'app-mst-placenta',
  templateUrl: './mst-placenta.component.html',
  styleUrls: ['./mst-placenta.component.css']
})
export class MstPlacentaComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdInsercaoBaixa') rdInsercaoBaixa: MatRadioButton;
  @ViewChild('slLocalizacao') slLocalizacao: MatSelect;
  @ViewChild('slGrau') slGrau: MatSelect;
  @ViewChild('inEspessura') inEspessura: ElementRef;
  @ViewChild('slCicatriz') slCicatriz: MatSelect;

  localizacoes: ArrayDropDown[] = [
    { value: 'anterior', viewValue: 'Anterior' },
    { value: 'posterior', viewValue: 'Posterior' },
    { value: 'fúndica', viewValue: 'Fúndica' },
    { value: 'lateral direita', viewValue: 'Lateral direita' },
    { value: 'lateral esquerda', viewValue: 'Lateral esquerda' }
  ];

  graus: ArrayDropDown[] = [
    { value: '0', viewValue: '0' },
    { value: 'I', viewValue: 'I' },
    { value: 'II', viewValue: 'II' },
    { value: 'III', viewValue: 'III' }
  ];

  opcoes: ArrayDropDown[] = [
    { value: 'Não', viewValue: 'Não' },
    { value: 'Sim', viewValue: 'Sim' }
  ];

  inPlacentaInvalid: boolean = true;
  inGrauInvalid: boolean = true;
  inLocalizacaoInvalid: boolean = true;
  showCicatriz: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  clickDefault() {
    this.catchIDs();
  }

  clickInsercao() {
    this.catchIDs();
  }

  changeSelect() {
    this.catchIDs();
  }

  blurParametros() {
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit('Clean');

    this.removeInvalidField();

    if (this.rdInsercaoBaixa.checked) {
      this.showCicatriz = this.slLocalizacao.value === 'anterior';
    } else {
      this.showCicatriz = false;
    }

    if (this.rdNormal && this.rdNormal.checked) {
      this.termoSelecionado.emit(this.rdNormal.id);
    }

    if (this.rdInsercaoBaixa && this.rdInsercaoBaixa.checked) {
      this.termoSelecionado.emit(this.rdInsercaoBaixa.id);
      if (this.slLocalizacao && this.slLocalizacao.value == 'anterior') {
        if (this.slCicatriz && this.slCicatriz.value == 'Sim') {
          let objeto = { elemento: this.slCicatriz, isPrimeiraLesao: true };
          this.termosGenericos.emit(objeto);
        }
      }
    }

    if (this.slLocalizacao && this.slLocalizacao.value) {
      let objeto = { elemento: this.slLocalizacao, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.slGrau && this.slGrau.value) {
      let objeto = { elemento: this.slGrau, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    if (this.inEspessura && this.inEspessura.nativeElement.value) {
      let objeto = { elemento: this.inEspessura.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
    }

    setTimeout(() => {
      if ((this.slLocalizacao && this.slLocalizacao.value) && 
          (this.slGrau && this.slGrau.value) && 
          (!this.slCicatriz || (this.slCicatriz && this.slCicatriz.value))) {
        this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'Placenta' });
      } else {
        this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'Placenta' });
      }
    
      this.listaCompleta.emit();
    }, 0);

    this.listaCompleta.emit();
  }
  removeInvalidField() {
    if (this.slLocalizacao && this.slLocalizacao.value) {
      this.inLocalizacaoInvalid = false;
    } else {
      this.inLocalizacaoInvalid = true;
    }
    if (this.slGrau && this.slGrau.value) {
      this.inGrauInvalid = false;
    } else {
      this.inGrauInvalid = true;
    }
    if (this.slCicatriz && this.slCicatriz.value) {
      this.inPlacentaInvalid = false;
    } else {
      this.inPlacentaInvalid = true;
    }
  }
}
