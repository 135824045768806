import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatRadioButton } from "@angular/material";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-corionicidade-gemelar",
  templateUrl: "./corionicidade-gemelar.component.html",
  styleUrls: ["./corionicidade-gemelar.component.css"],
})
export class CorionicidadeGemelarComponent implements OnInit {
  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();

  @ViewChild("rdDicorionica") rdDicorionica: MatRadioButton;
  @ViewChild("rdMonocronionicaDiamniotica")
  rdMonocronionicaDiamniotica: MatRadioButton;
  @ViewChild("rdMonocronionicaMonoamniotica")
  rdMonocronionicaMonoamniotica: MatRadioButton;
  @ViewChild("rdMassaPlacentariaUnica") rdMassaPlacentariaUnica: MatRadioButton;
  @ViewChild("rdTricorionicaTriamniotica")
  rdTricorionicaTriamniotica: MatRadioButton;
  @ViewChild("rdDicorionicaTriamniotica")
  rdDicorionicaTriamniotica: MatRadioButton;
  @ViewChild("rdMonocorionicaTriamniotica")
  rdMonocorionicaTriamniotica: MatRadioButton;
  @ViewChild("rdOutros") rdOutros: MatRadioButton;
  @ViewChild("inOutros") inOutros: ElementRef;

  showAndHideCorionicidade: any;
  showAndHide: any;

  quantidadeFetos: number = 2;
  constructor(private _common: CommonService) {}

  ngOnInit() {
    this.showAndHideCorionicidade = {
      fetos2: true,
      fetos3: false,
      fetos4: false,
    };
    this.showAndHide = {
      sahOutros: false,
    };
    this._common.quantidadeFeto.subscribe((quantidade: any) => {
      this.quantidadeFetos = quantidade;
      //atualizar de acordo com quantidade de fetos
      this.showAndHideCorionicidade = {
        fetos2: false,
        fetos3: false,
        fetos4: false,
      };

      if (quantidade >= 2 && quantidade <= 4) {
        this.showAndHideCorionicidade[`fetos${quantidade}`] = true;
      }
    });
  }

  clickRadio() {
    this.showAndHide.sahOutros = false;
    this.catchIDs();
  }

  blurInput() {
    this.catchIDs();
  }

  clickRadioOutros() {
    if (this.rdOutros && this.rdOutros.checked) {
      this.showAndHide.sahOutros = true;
    } else {
      this.showAndHide.sahOutros = false;
    }
    this.catchIDs();
  }

  catchIDs() {
    this.termoSelecionado.emit("Clean");

    if (this.rdDicorionica && this.rdDicorionica.checked) {
      this.termoSelecionado.emit(this.rdDicorionica.id);
    }

    if (
      this.rdMonocronionicaDiamniotica &&
      this.rdMonocronionicaDiamniotica.checked
    ) {
      this.termoSelecionado.emit(this.rdMonocronionicaDiamniotica.id);
    }

    if (
      this.rdMonocronionicaMonoamniotica &&
      this.rdMonocronionicaMonoamniotica.checked
    ) {
      this.termoSelecionado.emit(this.rdMonocronionicaMonoamniotica.id);
    }

    if (this.rdMassaPlacentariaUnica && this.rdMassaPlacentariaUnica.checked) {
      this.termoSelecionado.emit(this.rdMassaPlacentariaUnica.id);
    }

    if (
      this.rdTricorionicaTriamniotica &&
      this.rdTricorionicaTriamniotica.checked
    ) {
      this.termoSelecionado.emit(this.rdTricorionicaTriamniotica.id);
    }

    if (
      this.rdDicorionicaTriamniotica &&
      this.rdDicorionicaTriamniotica.checked
    ) {
      this.termoSelecionado.emit(this.rdDicorionicaTriamniotica.id);
    }

    if (
      this.rdMonocorionicaTriamniotica &&
      this.rdMonocorionicaTriamniotica.checked
    ) {
      this.termoSelecionado.emit(this.rdMonocorionicaTriamniotica.id);
    }

    if (this.rdOutros && this.rdOutros.checked) {
      this.termoSelecionado.emit(this.rdOutros.id);
      if (this.inOutros && this.inOutros.nativeElement.value) {
        var elemento = { id: this.inOutros.nativeElement.id, value: this.inOutros.nativeElement.value };
        var objeto = { elemento: elemento, isPrimeiraLesao: true};
        this.termosGenericos.emit(objeto);
      }
    }

    this.listaCompleta.emit();
  }
}
