import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatDialog, MatRadioButton, MatSelect } from '@angular/material';
import { ArrayDropDown } from 'src/app/models/Helper/ArrayDropDown';
import { ManejoRcuiDialogComponent } from 'src/app/utils/dialog/imagens/manejo-rciu-dialog/manejo-rciu-dialog.component';

@Component({
  selector: 'app-dopplervelocimetria',
  templateUrl: './dopplervelocimetria.component.html',
  styleUrls: ['./dopplervelocimetria.component.css']
})
export class DopplervelocimetriaComponent implements OnInit {

  @Output() termoSelecionado = new EventEmitter();
  @Output() termosGenericos = new EventEmitter();
  @Output() listaCompleta = new EventEmitter();
  @Output() enviaMedida = new EventEmitter();
  @Output() tabelaTexto = new EventEmitter();
  @Output() showTabela = new EventEmitter();
  @Output() lesoesSelecionadas = new EventEmitter();


  @ViewChild('rdNormal') rdNormal: MatRadioButton;
  @ViewChild('rdAlterada') rdAlterada: MatRadioButton;

  @ViewChild('inAUterinaDir') inAUterinaDir: ElementRef;
  @ViewChild('inAUterinaEsq') inAUterinaEsq: ElementRef;
  @ViewChild('inIPMedioAUterinas') inIPMedioAUterinas: ElementRef;
  @ViewChild('inPercentilAUterinas') inPercentilAUterinas: ElementRef;
  @ViewChild('inAUmbilicais') inAUmbilicais: ElementRef;
  @ViewChild('inPercentilAUmbilicais') inPercentilAUmbilicais: ElementRef;
  @ViewChild('inACerebral') inACerebral: ElementRef;
  @ViewChild('inPercentilAcerebral') inPercentilAcerebral: ElementRef;
  @ViewChild('inRelacao') inRelacao: ElementRef;
  @ViewChild('inPercentilRelacao') inPercentilRelacao: ElementRef;
  @ViewChild('inPicoSistolico') inPicoSistolico: ElementRef;
  @ViewChild('inMom') inMom: ElementRef;
  @ViewChild('inDucto') inDucto: ElementRef;
  @ViewChild('slAUmbilicais') slAUmbilicais: MatSelect;


  AUmbilicais: ArrayDropDown[] = [
    {value: 'diástole zero', viewValue: 'Diástole zero'},
    {value: 'diástole reversa', viewValue: 'Diástole reversa'}
  ];

  showAndHideTerms: any;
  unidadeMedida: string;
  calculoRelacao
  ipMedioAUterina!:any;
  switchTabelaTextoChecked: boolean = false;
  countLesoes: number;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.showAndHideTerms = {
      sahNormal: true,
      sahAlterada: false
    }
    let cmValidator = localStorage.getItem('cmRule');
    if(cmValidator == undefined || cmValidator == null){
      this.unidadeMedida = 'cm';
    } else{
        let cmRule = JSON.parse(localStorage.getItem('cmRule'));
        this.unidadeMedida = cmRule.active ? 'mm' : 'cm';
      }
  }

  clickNormal(){
    this.showAndHideTerms.sahNormal = true;
    this.showAndHideTerms.sahAlterada = false;
    this.catchIDs();
  }

  clickAlterada(){
    this.showAndHideTerms.sahNormal = false;
    this.showAndHideTerms.sahAlterada = true;
    this.catchIDs();
  }

  clickTabelaManejo(){
    this.dialog.open(ManejoRcuiDialogComponent, {});
  }

  blurParametros() {
    this.catchIDs();
    this.tabelaTexto.emit('element');
    this.handleCalculaRelacao();
  }
  handleCalculaAAMedio(aaEsquerda, aaDireita){
    let soma =+aaEsquerda + +aaDireita;
    this.ipMedioAUterina = (soma / 2).toFixed(2);
  }
  handleCalculaRelacao(){
    if(this.inACerebral && this.inACerebral.nativeElement.value && this.inAUmbilicais && this.inAUmbilicais.nativeElement.value){
      this.calculoRelacao = twoDecimalPlacesIfCents(this.inACerebral.nativeElement.value / this.inAUmbilicais.nativeElement.value);
    }
    function twoDecimalPlacesIfCents(amount){
      return (amount % 1 !== 0) ? amount.toFixed(2) : amount;
    }
  }

  changeSelect(){
    this.catchIDs();
  }

  switchTabelaTexto(){
    this.switchTabelaTextoChecked = !this.switchTabelaTextoChecked;
    this.showTabela.emit(this.switchTabelaTextoChecked);
  }

  catchIDs() {
    this.countLesoes = 0;
    this.enviaMedida.emit('Clean');
    this.termoSelecionado.emit('Clean');

    this.emitArteriasUterinas();

    if(this.countLesoes > 0){
      this.emitArteriasUmbilicaisLesao();
    } else{
      this.emitArteriasUmbilicais();
    }

    if(this.countLesoes > 0){
      this.emitArteriaCerebralLesao();
    } else {
      this.emitArteriaCerebral();
    }

    if(this.countLesoes > 0){
      this.emitRelacaoLesao();
    } else {
      this.emitRelacao();
    }
    
    if(this.countLesoes > 0){
      this.emitPicoSistolicoLesao();
    } else {
      this.emitPicoSistolico();
    }

    if(this.countLesoes > 0){
      this.emitDuctoLesao();
    } else {
      this.emitDucto();
    }

    
    this.showTabela.emit(this.switchTabelaTextoChecked);
    this.listaCompleta.emit();
  }

  emitArteriasUterinas(){
    if(this.inPercentilAUterinas && this.inPercentilAUterinas.nativeElement.value && this.inIPMedioAUterinas && this.inIPMedioAUterinas.nativeElement.value){
      if(this.rdNormal && this.rdNormal.checked){
        this.termoSelecionado.emit(this.rdNormal.id);
      } else if (this.rdAlterada && this.rdAlterada.checked){
        this.termoSelecionado.emit(this.rdAlterada.id);
        this.enviaMedida.emit('Clean');
      }
    }
    
    if(this.inAUterinaEsq && this.inAUterinaEsq.nativeElement.value && this.inAUterinaDir && this.inAUterinaDir.nativeElement.value){
      this.countLesoes++;
      this.handleCalculaAAMedio(this.inAUterinaEsq.nativeElement.value, this.inAUterinaDir.nativeElement.value);
      if(this.inPercentilAUterinas){
        const objeto = { elemento: this.inPercentilAUterinas.nativeElement, isPrimeiraLesao: true };
        this.termosGenericos.emit(objeto);
        this.enviaMedida.emit(objeto);
      }
   }

   if(this.inAUterinaEsq && this.inAUterinaEsq.nativeElement.value){
     const objeto = { elemento: this.inAUterinaEsq.nativeElement, isPrimeiraLesao: true };
     this.termosGenericos.emit(objeto);
   }
   if(this.inAUterinaDir && this.inAUterinaDir.nativeElement.value){
     const objeto = { elemento: this.inAUterinaDir.nativeElement, isPrimeiraLesao: true };
     this.termosGenericos.emit(objeto);
   }
   if(this.inIPMedioAUterinas && this.inIPMedioAUterinas.nativeElement.value){
     const objeto = { elemento: this.inIPMedioAUterinas.nativeElement, isPrimeiraLesao: true };
     this.termosGenericos.emit(objeto);
     this.enviaMedida.emit(objeto);
   }
   
   
  }
  
  emitArteriasUmbilicais(){
    
    if(this.rdNormal && this.rdNormal.checked){
      if(this.inPercentilAUmbilicais && this.inPercentilAUmbilicais.nativeElement.value && this.inAUmbilicais && this.inAUmbilicais.nativeElement.value){
        this.countLesoes++;
        this.termoSelecionado.emit(this.rdNormal.id);
      }
    } else if (this.rdAlterada && this.rdAlterada.checked){
      if(this.slAUmbilicais && this.slAUmbilicais.value){
        this.countLesoes++;
        this.termoSelecionado.emit(this.rdAlterada.id);
        this.enviaMedida.emit('Clean');
      }
    }

    if(this.inAUmbilicais && this.inAUmbilicais.nativeElement.value){
      
      const objeto = { elemento: this.inAUmbilicais.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.slAUmbilicais && this.slAUmbilicais.value){
      const newElement = {elemento: {id: '5579', value: 'DZ'}};
      if(this.slAUmbilicais.value == 'diástole zero'){
        newElement.elemento.value = 'diástole zero';
        const objeto2 = { elemento: newElement, isPrimeiraLesao: true };
        this.enviaMedida.emit(objeto2.elemento);
      } else if(this.slAUmbilicais.value == 'diástole reversa'){
        newElement.elemento.value = 'diástole reversa';
        const objeto3 = { elemento: newElement, isPrimeiraLesao: true };
        this.enviaMedida.emit(objeto3.elemento);
      }
      const objeto2 = { elemento: newElement.elemento, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto2);
    }
    if(this.inPercentilAUmbilicais && this.inPercentilAUmbilicais.nativeElement.value){
      const objeto = { elemento: this.inPercentilAUmbilicais.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
  }

  emitArteriasUmbilicaisLesao(){
    if(this.rdNormal && this.rdNormal.checked){
      if(this.inPercentilAUmbilicais && this.inPercentilAUmbilicais.nativeElement.value && this.inAUmbilicais && this.inAUmbilicais.nativeElement.value){
        this.countLesoes++;
        const objeto = { id: this.rdNormal.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
      }
    } else if (this.rdAlterada && this.rdAlterada.checked){
      if(this.slAUmbilicais && this.slAUmbilicais.value){
        this.countLesoes++;
        const objeto = { id: this.rdAlterada.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
        this.enviaMedida.emit('Clean');
      }
    }
    
    if(this.inAUmbilicais && this.inAUmbilicais.nativeElement.value){
      
      const objeto = { elemento: this.inAUmbilicais.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.slAUmbilicais && this.slAUmbilicais.value){
      const newElement = {elemento: {id: '5579', value: 'DZ'}};
      if(this.slAUmbilicais.value == 'diástole zero'){
        newElement.elemento.value = 'diástole zero';
        const objeto2 = { elemento: newElement, isPrimeiraLesao: false };
        this.enviaMedida.emit(objeto2.elemento);
      } else if(this.slAUmbilicais.value == 'diástole reversa'){
        newElement.elemento.value = 'diástole reversa';
        const objeto3 = { elemento: newElement, isPrimeiraLesao: false };
        this.enviaMedida.emit(objeto3.elemento);
      }
      const objeto2 = { elemento: newElement.elemento, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto2);
    }
    if(this.inPercentilAUmbilicais && this.inPercentilAUmbilicais.nativeElement.value){
      const objeto = { elemento: this.inPercentilAUmbilicais.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
  }

  emitArteriaCerebral(){
    if(this.inACerebral && this.inACerebral.nativeElement.value){
      this.countLesoes++;
      if(this.rdNormal && this.rdNormal.checked){
        this.termoSelecionado.emit(this.rdNormal.id);
      } else if (this.rdAlterada && this.rdAlterada.checked){
        this.termoSelecionado.emit(this.rdAlterada.id);
        this.enviaMedida.emit('Clean');
      }
    }
    if(this.inACerebral && this.inACerebral.nativeElement.value){
      const objeto = { elemento: this.inACerebral.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inPercentilAcerebral && this.inPercentilAcerebral.nativeElement.value){
      const objeto = { elemento: this.inPercentilAcerebral.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
  }

  emitArteriaCerebralLesao(){
    if(this.inACerebral && this.inACerebral.nativeElement.value){
      this.countLesoes++;
      if(this.rdNormal && this.rdNormal.checked){
        const objeto = { id: this.rdNormal.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
      } else if (this.rdAlterada && this.rdAlterada.checked){
        const objeto = { id: this.rdAlterada.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
        this.enviaMedida.emit('Clean');
      }
    }
    if(this.inACerebral && this.inACerebral.nativeElement.value){
      const objeto = { elemento: this.inACerebral.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inPercentilAcerebral && this.inPercentilAcerebral.nativeElement.value){
      const objeto = { elemento: this.inPercentilAcerebral.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
  }

  emitRelacao(){
    if(this.inRelacao && this.inRelacao.nativeElement.value){
      this.countLesoes++;
      if(this.rdNormal && this.rdNormal.checked){
        this.termoSelecionado.emit(this.rdNormal.id);
      } else if (this.rdAlterada && this.rdAlterada.checked){
        this.termoSelecionado.emit(this.rdAlterada.id);
        this.enviaMedida.emit('Clean');
      }
    }
    if(this.inRelacao && this.inRelacao.nativeElement.value){
      const objeto = { elemento: this.inRelacao.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inPercentilRelacao && this.inPercentilRelacao.nativeElement.value){
      const objeto = { elemento: this.inPercentilRelacao.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
  }

  emitRelacaoLesao(){
    if(this.inRelacao && this.inRelacao.nativeElement.value){
      this.countLesoes++;
      if(this.rdNormal && this.rdNormal.checked){
        const objeto = { id: this.rdNormal.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
      } else if (this.rdAlterada && this.rdAlterada.checked){
        const objeto = { id: this.rdAlterada.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
        this.enviaMedida.emit('Clean');
      }
    }
    if(this.inRelacao && this.inRelacao.nativeElement.value){
      const objeto = { elemento: this.inRelacao.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inPercentilRelacao && this.inPercentilRelacao.nativeElement.value){
      const objeto = { elemento: this.inPercentilRelacao.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
  }

  emitPicoSistolico(){
    if(this.inPicoSistolico && this.inPicoSistolico.nativeElement.value){
      this.countLesoes++;
      if(this.rdNormal && this.rdNormal.checked){
        this.termoSelecionado.emit(this.rdNormal.id);
      } else if (this.rdAlterada && this.rdAlterada.checked){
        this.termoSelecionado.emit(this.rdAlterada.id);
        this.enviaMedida.emit('Clean');
      }
    }
    if(this.inPicoSistolico && this.inPicoSistolico.nativeElement.value){
      const objeto = { elemento: this.inPicoSistolico.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inMom && this.inMom.nativeElement.value){
      const objeto = { elemento: this.inMom.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
  }

  emitPicoSistolicoLesao(){
    if(this.inPicoSistolico && this.inPicoSistolico.nativeElement.value){
      this.countLesoes++;
      if(this.rdNormal && this.rdNormal.checked){
        const objeto = { id: this.rdNormal.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
      } else if (this.rdAlterada && this.rdAlterada.checked){
        const objeto = { id: this.rdAlterada.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
        this.enviaMedida.emit('Clean');
      }
    }
    if(this.inPicoSistolico && this.inPicoSistolico.nativeElement.value){
      const objeto = { elemento: this.inPicoSistolico.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
    if(this.inMom && this.inMom.nativeElement.value){
      const objeto = { elemento: this.inMom.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
  }

  emitDucto(){
    if(this.inDucto && this.inDucto.nativeElement.value){
      this.countLesoes++;
      if(this.rdNormal && this.rdNormal.checked){
        this.termoSelecionado.emit(this.rdNormal.id);
      } else if (this.rdAlterada && this.rdAlterada.checked){
        this.termoSelecionado.emit(this.rdAlterada.id);
        this.enviaMedida.emit('Clean');
      }
    }
    if(this.inDucto && this.inDucto.nativeElement.value){
      const objeto = { elemento: this.inDucto.nativeElement, isPrimeiraLesao: true };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
  }

  emitDuctoLesao(){
    if(this.inDucto && this.inDucto.nativeElement.value){
      this.countLesoes++;
      if(this.rdNormal && this.rdNormal.checked){
        const objeto = { id: this.rdNormal.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
      } else if (this.rdAlterada && this.rdAlterada.checked){
        const objeto = { id: this.rdAlterada.id, cenario: this.countLesoes};
        this.lesoesSelecionadas.emit(objeto);
        this.enviaMedida.emit('Clean');
      }
    }
    if(this.inDucto && this.inDucto.nativeElement.value){
      const objeto = { elemento: this.inDucto.nativeElement, isPrimeiraLesao: false };
      this.termosGenericos.emit(objeto);
      this.enviaMedida.emit(objeto);
    }
  }
}
