import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, Renderer2 } from '@angular/core';
import { Calculadora } from '../../../../utils/calculadora';
import * as moment from 'moment';
import { MatRadioButton, MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-obs-calculadora',
  templateUrl: './obs-calculadora.component.html',
  styleUrls: ['./obs-calculadora.component.css']
})
export class ObsCalculadoraComponent implements OnInit {
  @Output() calculadoraEmmit = new EventEmitter();
  @Input() transvPrimeiroTrimestre: boolean;
  @Output() calculadoraEmmitObs = new EventEmitter();
  @Output() camposObrigatoriosPreenchidos = new EventEmitter();

  @ViewChild('inDataUltimo') inDataUltimo: ElementRef;
  @ViewChild('inDataDeste') inDataDeste: ElementRef;
  @ViewChild('inDataPrimeiro') inDataPrimeiro: ElementRef;

  @ViewChild('inSemanas') inSemanas: ElementRef;
  @ViewChild('inDias') inDias: ElementRef;

  @ViewChild('ckSem') ckSem: MatCheckbox;
  @ViewChild('ckPrimeiroExame') ckPrimeiroExame: MatCheckbox;
  @ViewChild('ckDppDum') ckDppDum: MatCheckbox;

  showAndHideTerms: any;
  diaAtual: Date;
  resultado: Array<any>;
  resultadoObs: Array<any>;

  ckSemInvalid: boolean = true;
  inDataUltimoInvalid: boolean = true;
  inDataPrimeiroInvalid: boolean = true;

  constructor(private _calc: Calculadora) { }

  ngOnInit() {
    this.diaAtual = new Date();
    this.diaAtual.setDate(this.diaAtual.getDate());
    this.showAndHideTerms = {
      sahSem: true
    };
  }

  blurData() {
    this.catchIDs();
  }

  blurMedindo() {
    if (this.inDias.nativeElement.value >= 6) {
      this.VerificaDiasIG()
    }
    
    this.catchIDs();
  }

  VerificaDiasIG() {
    this.inDias.nativeElement.value = 6;
  }

  clickSem() {
    if((this.ckSem && this.ckSem.checked) || (this.ckPrimeiroExame && this.ckPrimeiroExame.checked))
      this.showAndHideTerms.sahSem = false;
    else
      this.showAndHideTerms.sahSem = true;
    this.catchIDs();
  }

  clickDppDum() {
    this.catchIDs();
  }

  catchIDs() {
    this.resultado = new Array();
    this.resultadoObs = new Array();
    let frase = '';
    let conclusao = '';
    let diaDum = '';
    let diaDeste = '';
    let diaPrim = '';
    let s = 0;
    let d = 0;
    let dataDum: Date;
    let dataDeste: Date;
    let dataPrim: Date;

    this.removeInvalidField();

    if(this.transvPrimeiroTrimestre){
      if(this.ckPrimeiroExame && this.ckPrimeiroExame.checked){
        frase="Este é o primeiro exame da paciente nesta gestação.";
        this.resultado.push(frase);
      }
      if(this.ckPrimeiroExame && !this.ckPrimeiroExame.checked){
        frase="unCheck";
        this.resultado.push(frase);
      }
        frase = '';
        if (this.inDataUltimo &&
          this.inDataUltimo.nativeElement.value != '' &&
          this.inDataDeste &&
          this.inDataDeste.nativeElement.value != '' &&
          this.inDataPrimeiro &&
          this.inDataPrimeiro.nativeElement.value != '' &&
          this.inSemanas &&
          this.inSemanas.nativeElement.value) {

          // Data da Ultima Menstruação
          diaDum = moment(this.inDataUltimo.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDum = moment(diaDum, 'DD/MM/YYYY').toDate();

          // Data deste Exame
          diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

          // Data da Primeira Ultrasom
          diaPrim = moment(this.inDataPrimeiro.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataPrim = moment(diaPrim, 'DD/MM/YYYY').toDate();

          //DPP
          s = this.inSemanas.nativeElement.value;
          d = this.inDias.nativeElement.value;

          frase = 'Data da última menstruação: ';
          frase += diaDum + '</br>IG (menstrual): ';
          frase += this._calc.calculoIGMenstrual(dataDeste, dataDum);
          frase += '</br>IG (Primeiro Ultrassom): ';
          frase += this._calc.calculaDppIg(s,d,dataDeste,dataPrim) +'.';
          this.resultado.push(frase);

          conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
          conclusao += this._calc.calculoIGMenstrual(dataDeste, dataDum) + ' pela amenorréia e ';
          conclusao += this._calc.calculaDppIg(s,d,dataDeste,dataPrim) + ' pelo primeiro ultrassom.';
          this.resultado.push(conclusao);
          var conclusaoObs = 'Idade gestacional de ';
          conclusaoObs += this._calc.calculoIGPrimUltrasom(dataDeste, dataPrim, this.inSemanas.nativeElement.value, this.inDias.nativeElement.value) + ' pela ultrassonografia precoce.';
          this.resultadoObs.push(conclusaoObs);

        }

        // Data da última menstruação não informada
        if (this.inDataUltimo &&
          this.inDataUltimo.nativeElement.value == '' &&
          this.inDataDeste &&
          this.inDataDeste.nativeElement.value != '' &&
          this.inDataPrimeiro &&
          this.inDataPrimeiro.nativeElement.value != '') {

          // Data da Ultima Menstruação não informada
          if (this.inDataUltimo.nativeElement.value == '') {
            diaDum = 'n/a'
          }

          // Data deste Exame
          diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

          // Data da Primeira Ultrasom
          diaPrim = moment(this.inDataPrimeiro.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataPrim = moment(diaPrim, 'DD/MM/YYYY').toDate();

          frase = 'Data da última menstruação: ';
          frase += diaDum + '</br>IG (menstrual): ';
          frase += 'n/a';
          frase += '</br>IG (Primeiro Ultrassom): ';
          frase += this._calc.calculoIGPrimUltrasom(dataDeste, dataPrim, this.inSemanas.nativeElement.value, this.inDias.nativeElement.value);
          this.resultado.push(frase);

          conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
          conclusao += this._calc.calculoIGPrimUltrasom(dataDeste, dataPrim, this.inSemanas.nativeElement.value, this.inDias.nativeElement.value) + ' pelo primeiro ultrassom.';
          this.resultado.push(conclusao);

          var conclusaoObs = 'Idade gestacional de ';
          conclusaoObs += this._calc.calculoIGPrimUltrasom(dataDeste, dataPrim, this.inSemanas.nativeElement.value, this.inDias.nativeElement.value) + ' pela ultrassonografia precoce.';
          this.resultadoObs.push(conclusaoObs);
        }

        // Data da última menstruação não informada e
        // Data do Primeiro Ulrtrasom não informado
        if (this.inDataDeste &&
          this.inDataDeste.nativeElement.value != '' &&
          this.inDataUltimo &&
          this.inDataUltimo.nativeElement.value == '' &&
          this.inDataPrimeiro &&
          this.inDataPrimeiro.nativeElement.value == '') {

          // Data da Ultima Menstruação não informada
          if (this.inDataUltimo.nativeElement.value == '') {
            diaDum = 'n/a'
          }

          frase = 'Data da última menstruação: ';
          frase += diaDum + '</br>IG (menstrual): ';
          frase += 'n/a';
          frase += '</br>IG (Primeiro Ultrassom): ';
          frase += 'n/a';
          this.resultado.push(frase);

          conclusao = 'Gestação única, tópica, de concepto vivo.';
          this.resultado.push(conclusao);

          var conclusaoObs = '';
          this.resultadoObs.push(conclusaoObs);
        }

        //informar só DUM e DESTE
        if (this.inDataUltimo &&
          this.inDataUltimo.nativeElement.value != '' &&
          this.inDataDeste &&
          this.inDataDeste.nativeElement.value != '') {

          // Data da Ultima Menstruação
          diaDum = moment(this.inDataUltimo.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDum = moment(diaDum, 'DD/MM/YYYY').toDate();

          // Data deste Exame
          diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

          frase = 'Data da última menstruação: ';
          frase += diaDum + '</br>IG (menstrual): ';
          frase += this._calc.calculoIGMenstrual(dataDeste, dataDum) +'.';
          this.resultado.push(frase);

          conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
          conclusao += this._calc.calculoIGMenstrual(dataDeste, dataDum) + ' pela amenorréia.';
          this.resultado.push(conclusao);

          var conclusaoObs = '';
          this.resultadoObs.push(conclusaoObs);
        }

        // DPP pela DUM
        if (this.inDataUltimo &&
          this.inDataUltimo.nativeElement.value != '' &&
          this.inDataDeste &&
          this.inDataDeste.nativeElement.value != '' &&
          this.ckDppDum &&
          this.ckDppDum.checked) {

          // Data da Ultima Menstruação
          diaDum = moment(this.inDataUltimo.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDum = moment(diaDum, 'DD/MM/YYYY').toDate();

          // Data deste Exame
          diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

          frase = 'Data da última menstruação: ';
          frase += diaDum + '</br>IG (menstrual): ';
          frase += this._calc.calculoIGMenstrual(dataDeste, dataDum);
          frase += '</br>DPP: ';
          frase += this._calc.calculoDPP(dataDum);
          this.resultado.push(frase);

          conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
          conclusao += this._calc.calculoIGMenstrual(dataDeste, dataDum) + ' pela amenorréia.';
          this.resultado.push(conclusao);

          var conclusaoObs = '';
          this.resultadoObs.push(conclusaoObs);
        }

    }else{
      if(this.ckSem && this.ckSem.checked){
        frase="Ultrassom precoce não disponível para a datação.";
        this.resultado.push(frase);
        conclusao = 'Gestação única, tópica, de concepto vivo.';
        this.resultado.push(conclusao);
        var conclusaoObs = '';
        this.resultadoObs.push(conclusaoObs);
      }else if(this.ckSem && !this.ckSem.checked){
        frase='';
        conclusao = '';
        var conclusaoObs = '';
      }
        if (this.inDataUltimo &&
          this.inDataUltimo.nativeElement.value != '' &&
          this.inDataDeste &&
          this.inDataDeste.nativeElement.value != '' &&
          this.inDataPrimeiro &&
          this.inDataPrimeiro.nativeElement.value != '' &&
          this.inSemanas &&
          this.inSemanas.nativeElement.value) {

          // Data da Ultima Menstruação
          diaDum = moment(this.inDataUltimo.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDum = moment(diaDum, 'DD/MM/YYYY').toDate();

          // Data deste Exame
          diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

          // Data da Primeira Ultrasom
          diaPrim = moment(this.inDataPrimeiro.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataPrim = moment(diaPrim, 'DD/MM/YYYY').toDate();

          //DPP
          s = this.inSemanas.nativeElement.value;
          d = this.inDias.nativeElement.value;

          frase = 'Data da última menstruação: ';
          frase += diaDum + '</br>IG (menstrual): ';
          frase += this._calc.calculoIGMenstrual(dataDeste, dataDum);
          frase += '</br>IG (primeiro ultrassom): ';
          frase += this._calc.calculaDppIg(s,d,dataDeste,dataPrim) +'.';
          this.resultado.push(frase);

          /* conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
          conclusao += this._calc.calculoIGMenstrual(dataDeste, dataDum) + ' pela amenorréia e ';
          conclusao += this._calc.calculaDppIg(s,d,dataDeste,dataPrim) + ' pelo primeiro ultrassom.'; */
          
          conclusao = 'Idade gestacional de ';
          conclusao += this._calc.calculaDppIg(s,d,dataDeste,dataPrim) + ' pela ultrassonografia precoce.';
          this.resultado.push(conclusao);
          var conclusaoObs = 'Idade gestacional de ';
          conclusaoObs += this._calc.calculoIGPrimUltrasom(dataDeste, dataPrim, this.inSemanas.nativeElement.value, this.inDias.nativeElement.value) + ' pela ultrassonografia precoce.';
          this.resultadoObs.push(conclusaoObs);

        }

        // Data da última menstruação não informada
        if (this.inDataUltimo &&
          this.inDataUltimo.nativeElement.value == '' &&
          this.inDataDeste &&
          this.inDataDeste.nativeElement.value != '' &&
          this.inDataPrimeiro &&
          this.inDataPrimeiro.nativeElement.value != '') {

          // Data da Ultima Menstruação não informada
          if (this.inDataUltimo.nativeElement.value == '') {
            diaDum = 'n/a'
          }

          // Data deste Exame
          diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

          // Data da Primeira Ultrasom
          diaPrim = moment(this.inDataPrimeiro.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataPrim = moment(diaPrim, 'DD/MM/YYYY').toDate();

          frase = 'Data da última menstruação: ';
          frase += diaDum + '</br>IG (menstrual): ';
          frase += 'n/a';
          frase += '</br>IG (primeiro ultrassom): ';
          frase += this._calc.calculoIGPrimUltrasom(dataDeste, dataPrim, this.inSemanas.nativeElement.value, this.inDias.nativeElement.value);
          this.resultado.push(frase);

          conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
          conclusao += this._calc.calculoIGPrimUltrasom(dataDeste, dataPrim, this.inSemanas.nativeElement.value, this.inDias.nativeElement.value) + ' pelo primeiro ultrassom.';
          this.resultado.push(conclusao);

          var conclusaoObs = 'Idade gestacional de ';
          conclusaoObs += this._calc.calculoIGPrimUltrasom(dataDeste, dataPrim, this.inSemanas.nativeElement.value, this.inDias.nativeElement.value) + ' pela ultrassonografia precoce.';
          this.resultadoObs.push(conclusaoObs);
        }

        // Data da última menstruação não informada e
        // Data do Primeiro Ulrtrasom não informado
        if (this.inDataDeste &&
          this.inDataDeste.nativeElement.value != '' &&
          this.inDataUltimo &&
          this.inDataUltimo.nativeElement.value == '' &&
          this.inDataPrimeiro &&
          this.inDataPrimeiro.nativeElement.value == '') {

          // Data da Ultima Menstruação não informada
          if (this.inDataUltimo.nativeElement.value == '') {
            diaDum = 'n/a'
          }

          frase = 'Data da última menstruação: ';
          frase += diaDum + '</br>IG (menstrual): ';
          frase += 'n/a';
          frase += '</br>IG (primeiro ultrassom): ';
          frase += 'n/a';
          this.resultado.push(frase);

          conclusao = 'Gestação única, tópica, de concepto vivo.';
          this.resultado.push(conclusao);

          var conclusaoObs = '';
          this.resultadoObs.push(conclusaoObs);
        }

        //informar só DUM e DESTE
        if (this.inDataUltimo &&
          this.inDataUltimo.nativeElement.value != '' &&
          this.inDataDeste &&
          this.inDataDeste.nativeElement.value != '') {

          // Data da Ultima Menstruação
          diaDum = moment(this.inDataUltimo.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDum = moment(diaDum, 'DD/MM/YYYY').toDate();

          // Data deste Exame
          diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

          frase = 'Data da última menstruação: ';
          frase += diaDum + '</br>IG (menstrual): ';
          frase += this._calc.calculoIGMenstrual(dataDeste, dataDum) +'.';
          this.resultado.push(frase);

          conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
          conclusao += this._calc.calculoIGMenstrual(dataDeste, dataDum) + ' pela amenorréia.';
          this.resultado.push(conclusao);

          var conclusaoObs = '';
          this.resultadoObs.push(conclusaoObs);
        }

        // DPP pela DUM
        if (this.inDataUltimo &&
          this.inDataUltimo.nativeElement.value != '' &&
          this.inDataDeste &&
          this.inDataDeste.nativeElement.value != '' &&
          this.ckDppDum &&
          this.ckDppDum.checked) {

          // Data da Ultima Menstruação
          diaDum = moment(this.inDataUltimo.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDum = moment(diaDum, 'DD/MM/YYYY').toDate();

          // Data deste Exame
          diaDeste = moment(this.inDataDeste.nativeElement.value, 'DD/MM/YYYY').format('DD/MM/YYYY');
          dataDeste = moment(diaDeste, 'DD/MM/YYYY').toDate();

          frase = 'Data da última menstruação: ';
          frase += diaDum + '</br>IG (menstrual): ';
          frase += this._calc.calculoIGMenstrual(dataDeste, dataDum);
          frase += '</br>DPP: ';
          frase += this._calc.calculoDPP(dataDum);
          this.resultado.push(frase);

          conclusao = 'Gestação única, tópica, de concepto vivo, compatível com '
          conclusao += this._calc.calculoIGMenstrual(dataDeste, dataDum) + ' pela amenorréia.';
          this.resultado.push(conclusao);

          var conclusaoObs = '';
          this.resultadoObs.push(conclusaoObs);
        }
      }
      if ((this.ckSem && this.ckSem.checked) || (this.inDataUltimo && this.inDataUltimo.nativeElement.value) || (this.inDataPrimeiro && this.inDataPrimeiro.nativeElement.value)) {	
        this.camposObrigatoriosPreenchidos.emit({ ativo: true, nome: 'calc-idade-gestacional' });
      } else {
        this.camposObrigatoriosPreenchidos.emit({ ativo: false, nome: 'calc-idade-gestacional' });
      }

    this.calculadoraEmmitObs.emit(this.resultadoObs);
    this.calculadoraEmmit.emit(this.resultado);
  }

  removeInvalidField() {
    const isCkSemValid = this.ckSem && this.ckSem.checked;
    const isInDataUltimoValid = this.inDataUltimo && this.inDataUltimo.nativeElement.value;
    const isInDataPrimeiroValid = this.inDataPrimeiro && this.inDataPrimeiro.nativeElement.value;

    const isAnyFieldValid = isCkSemValid || isInDataUltimoValid || isInDataPrimeiroValid;

    this.ckSemInvalid = !isAnyFieldValid;
    this.inDataUltimoInvalid = !isAnyFieldValid;
    this.inDataPrimeiroInvalid = !isAnyFieldValid;
  }
}

