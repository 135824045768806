import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MstGenitaliaComponent } from './mst-genitalia/mst-genitalia.component';
import { MatFormFieldModule, MatOptionModule, MatSelectModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatOptionModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  exports: [
    MstGenitaliaComponent
  ],
  declarations: [
    MstGenitaliaComponent
  ]
})
export class SharedObstetricoModule { }
